/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {  put, select, takeEvery } from "redux-saga/effects";
import { State } from "../reducer/rootReducer";
import { apiSaga, updateComponentDataSaga } from "./apiSaga";
import { RestEntityMapper } from "../utils/RestEntityMapper";
import { SagaHelper } from "../utils/sagaHelper";
import { createUniqueId } from "../utils/cacheHandler";
import { getComponentName } from "../dictionary";
import { Logger } from "../utils/logger";

export enum DataSourceType {
  INLINE,
  API,
  ENTITY
}

interface ApiType {
  url: string,
  method?: string;
  transformer?: any; //transformer function or string.
  merger?: any;  // merger function / string.
}

interface EntityType {
  entity: string;
  type?: string;
  method?: string;
  post?: any,
  idField?: string;
  transformer?: any; // transformer function or string
  merger?: any; //merger function or string.
}

export interface DataSource {
  type: DataSourceType;
  api?: ApiType;
  entity?: EntityType
  data?: any;
  status: number;
  onLoad?: string;
  key?: string;
}
const SourceProcessorLock: any = {};
const getContext: any = (state: State) => state.context;
function* getSourceProcessed(action: any): any {

  const componentId = action.payload.id;


  try {
    if (!lockSourceForProcessing(componentId)) { return; }
    const state = action.payload.context;

    let source = state.config.source;
    if (!source) {
      return;
    }
    let computedKey = null;
    if (action.payload.source) {
      computedKey = computeSource(action);
      let status = source.status;
      if (source.key) {
        if (source.key !== computedKey) {
          status = false;
          const compName = getComponentName(componentId);
          if (compName === "fri::uicomponent:form") {
            action.payload._dispatch({ type: "CONTEXT_COMPONENT_UPDATE_DATA", payload: { "id": componentId, data: {} } });
          }
        }
      }
      if (!status) {
        source = action.payload.source;
        source.status = status;
        if (source.api && state.config.source.api) { source.api.transformer = state.config.source.api.transformer; }
        if (source.entity && state.config.source.entity) { source.entity.transformer = state.config.source.entity.transformer; }
      }
    }
    let transformer, merger = null;

    if (typeof transformer == "string" || !transformer) {
      if (source.api && source.api.transformer) { transformer = source.api.transformer; }
      if (source.entity && source.entity.transformer) { transformer = source.entity.transformer; }
      if (transformer) {
        transformer = prepareTransformer(transformer);
      }
    }
    if (typeof merger == "string" || !merger) {
      if (source.api && source.api.merger) { merger = source.api.merger; }
      if (source.entity && source.entity.merger) { merger = source.entity.merger; }
      if (merger) {
        merger = prepareMerger(merger);
      }
    }

    if (source.status === 1) {
      removeSourceProcessingLock(componentId);
      return;
    }

    if (source.type === DataSourceType.INLINE) {
      // data is already there, just set to /context/{id}/data
      const newSource = {
        isError: false,
        body: source.data
      }
      action.payload.merger = merger;
      action.payload.transformer = transformer;
      yield updateComponentDataSaga(action, newSource);
      removeSourceProcessingLock(componentId);
    } else if (source.type === DataSourceType.API) {
      yield apiSourceProcess(source.api, action, transformer, merger, componentId, computedKey);

    } else if (source.type === DataSourceType.ENTITY) {
      const api = { url: getEntityUrl(source, action), method: source.entity.method, cache: source.entity.cache, data: source.entity.data, header: source.entity.header }
      yield apiSourceProcess(api, action, transformer, merger, componentId, computedKey);
    }

    //source processor has done processing, now check onLoad to execute, 
    if (source.onLoad) {
      const context = yield select(getContext);
      //check multiple onload are defined
      if (source.onLoad.constructor === Array) {
        for (let i = 0; i < source.onLoad.length; i++) {
          const sourceOnLoad = compileFunction(source.onLoad[i]);
          if (context && context[componentId] && context[componentId].data && context[componentId].data.source && context[componentId].data.source.body) {
            const newContext = action.payload.context;
            newContext['data'] = context[componentId].data;
            sourceOnLoad(componentId, newContext, new SagaHelper(action.payload._dispatch));
          }
        }
      }
      else {
        const sourceOnLoad = compileFunction(source.onLoad);
        if (context && context[componentId] && context[componentId].data && context[componentId].data.source && context[componentId].data.source.body) {
          const newContext = action.payload.context;
          newContext['data'] = context[componentId].data;
          sourceOnLoad(componentId, newContext, new SagaHelper(action.payload._dispatch));
        }
      }

    }



  } catch (err) {
    Logger.error("sourceProcessorSaga.tsx", "getSourceProcessed error", err);
    removeSourceProcessingLock(componentId);
  } 
}

function getEntityUrl(source: DataSource, action: any) {
  let url;
  if (!source.entity) { return; }

  switch (source.entity.type) {
    case "LIST":
      url = RestEntityMapper(source.entity.entity).list({});
      break;
    case "CREATE":
      url = RestEntityMapper(source.entity.entity).create();
      break;
    case "UPDATE":
      url = RestEntityMapper(source.entity.entity).update(action.payload.props.request[source.entity.idField ? source.entity.idField : "id"]);
      break;
    case "GET":
    default:
      url = RestEntityMapper(source.entity.entity).get(action.payload.props.request[source.entity.idField ? source.entity.idField : "id"]);
  }
  return url;
}

function* apiSourceProcess(sourceAPI: any, action: any, transformer: any, merger: any, componentId: any, key: any = null): any {
  if (typeof sourceAPI == "string") {
    sourceAPI = { "url": sourceAPI };
  }
  let api = prepareAPIEndpoint(sourceAPI.url);
  api = typeof api == "function" ? api(action.payload.id, action.payload.props) : api;
  let data = sourceAPI.data ? prepareAPIBody(sourceAPI.data) : sourceAPI.data;
  data = typeof data == "function" ? data(action.payload.id, action.payload.props) : data;
  const payload = {
    payload: {
      transformer: transformer,
      url: api,
      id: componentId,
      data: data,
      method: sourceAPI.method,
      header: sourceAPI.header,
      expiry: sourceAPI.cache
    }
  };
  const apiResponse: any = yield apiSaga(payload);
  action.payload.merger = merger;
  action.payload.transformer = transformer;
  action.payload['key'] = key;
  removeSourceProcessingLock(componentId);
  yield updateComponentDataSaga(action, apiResponse);
}

function prepareAPIEndpoint(apiData: string): any {

  if (apiData.trim().match(/^function\(/)) {
    const functionName = "api";
    const api = function () { };
    eval(functionName + " = " + apiData);
    return api;
  }
  return apiData;
}

function prepareAPIBody(body: string): any {
  if (typeof body == "object") {
    return body;
  }
  if (body && body.trim().match(/^function\(/)) {
    const functionName = "data";
    const data = function () { };
    eval(functionName + " = " + body);
    return data;
  }
  return body;
}

export default function* sourceProcessorSaga() {
  yield takeEvery("SOURCE_PROCESS", getSourceProcessed);
  yield takeEvery("SOURCE_UPDATE", updateSourceSaga);
  yield takeEvery("UPDATE_SOURCE_STATUS", updateSourceStatusSaga)
}

function prepareTransformer(code: string): any {
  const functionName = "transformed";
  const transformed = function () { };
  eval(functionName + " = " + code);
  return transformed;
}
function prepareMerger(code: string): any {
  const functionName = "merger";
   const merger = function () { };
  eval(functionName + " = " + code);
  return merger;
}

function compileFunction(code: string) {
  const functionName = "sourceOnLoad";
  const sourceOnLoad = function (id: any, context: any, helper: any) { };
  // eslint-disable-next-line no-eval 
  eval(functionName + " = " + code);
  return sourceOnLoad;
}
function isSourceProcessing(componentId: string) {
  return SourceProcessorLock[componentId];
}
function lockSourceForProcessing(componentId: string): boolean {
  if (isSourceProcessing(componentId)) {
    return false;
  }
  SourceProcessorLock[componentId] = 1;
  return true;
}
function removeSourceProcessingLock(componentId: string) {
  SourceProcessorLock[componentId] = 0;
}

function* updateSourceStatusSaga(action: any) {
  yield put({ type: "COMPONENT_UPDATE_SOURCE_STATUS", payload: { id: action.payload.componentId } });
}

/**
 * This will update the source of the given component to be ready to be processed by SourceProcessorSaga
 * @param action 
 */
function* updateSourceSaga(action: any) {

  const componentId = action.payload.componentId;
  const source = action.payload.source;
  try {
    // check if SourceProcessor is already running.
    if (!lockSourceForProcessing(componentId)) { return; };

    source.status = 0;

    const message: any = { config: { source: source }, id: componentId, data: {} }

    yield put({ type: "COMPONENT_UPDATE_CONFIG", payload: message });
  } catch (err) {
    Logger.error("sourceProcessorSaga.tsx", "updateSourceSaga error", err); 
  } finally {
    removeSourceProcessingLock(componentId);
  }

}

function computeSource(action: any) {
  const source = action.payload.source;
  let url;
  let body;
  let method;
  let header;
  if (source.type === DataSourceType.API) {
    url = source.api.url;
    body = source.api.data;
    method = source.api.method;
    header = source.api.header;
  } else if (source.type === DataSourceType.ENTITY) {
    url = getEntityUrl(source, action);
    body = source.entity.data;
    method = source.entity.method;
    header = source.entity.header;
  } else if (source.type === DataSourceType.INLINE) {
    return null;
  }
  let api = prepareAPIEndpoint(url);
  api = typeof api == "function" ? api(action.payload.id, action.payload.props) : api;
  let data = body ? prepareAPIBody(body) : body;
  data = typeof data == "function" ? data(action.payload.id, action.payload.props) : data;
  const payloadSourceData = {
    url: api,
    method: method,
    data: data,
    header: header ? header : null
  };
  const key = createUniqueId(payloadSourceData);
  return key;
}